import {
  CurrentOrganizationQuery,
  EventQuery,
  GetI18nQuery,
  SeoMetadata,
} from "~graphql/sdk";

// Used to store global state.
// Initially created to store the organization after
// it has been loaded on the server so we don't have to load it on
// every page load.

type StoreProps = {
  organization: CurrentOrganizationQuery["currentOrganization"] | null;
  host: string;
  seoMetadata: Pick<
    SeoMetadata,
    | "title"
    | "description"
    | "origin"
    | "eventId"
    | "membershipId"
    | "keywords"
    | "openGraphImageUrl"
  >;
  i18n?: GetI18nQuery["getI18n"]["data"];
};
export const store: StoreProps = {
  organization: null,
  host: "",
  seoMetadata: null,
  i18n: null,
};
